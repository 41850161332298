import React from 'react'
import styles from './Table.module.css'
import { Table as BootstrapTable } from 'react-bootstrap'
import { BACKEND_DOMAIN, shared_filesHeaderBody } from '../../../assets/const'
import { AiOutlineFilePdf } from 'react-icons/ai';

const Table = ({
    lessons,
    list,
    filesRequest,
    activePage,
    values,
    setValues
}) => {

    const handleDownload = (fileLink) => {
        const link = document.createElement('a');
        link.setAttribute('href', fileLink);
        link.setAttribute('target', 'blank');
        link.setAttribute('rel', 'noopener noreferrer');
        link.click();
    }

    const renderList = (file, i) => {
        const { filename, filepath, lesson_id, parallel, author, created_at, organization, mimetype } = file;
        const lessonsName = lessons?.find(el => el.LID===lesson_id)?.name;
        const fileLink = `${BACKEND_DOMAIN || ''}/${filepath}/${filename}`;
        const type = mimetype.split('/')[0] === 'image' ? 'img' : 'file';
        return (
            <tr key={i}>
                <td className={styles.titleTd} colSpan={2}>{lessonsName}</td>
                <td className={styles.titleTd} colSpan={2}>
                    <a href={fileLink} download={true} target='_blank' rel='noopener noreferrer'>
                        {type === 'img' ? <img onClick={() => handleDownload(fileLink)} src={fileLink} className={styles.image} /> : <i className={styles.icon}><AiOutlineFilePdf /></i>}
                        {filename}
                    </a>
                </td>
                <td className={styles.titleTd} colSpan={1}>{parallel}</td>
                <td className={styles.titleTd} colSpan={2}>{author}</td>
                <td className={styles.titleTd} colSpan={2}>{created_at}</td>
                <td className={styles.titleTd} colSpan={4}>{organization}</td>
            </tr>
        )
    }

    const getBySort = (name) => {
        let copyValue = '';
        if (values.sort == name) {
            copyValue = { ...values, sort: ''};
            setValues(prev => ({ ...values, sort: ''}));
        } else {
            copyValue = { ...values, sort: name};
            setValues(prev => ({ ...values, sort: name}));
        }
        filesRequest((activePage, 10, copyValue));
    }

  return (
    <div className={styles.table}>
        <BootstrapTable>
            <thead>
            <tr>
                {shared_filesHeaderBody.map(el =>
                <th 
                key={el.value}
                colSpan={el.colSpan} 
                onClick={() => getBySort(el.value)} 
                title={`Сортировать по ${el.name}`}
                style={{background: `${values.sort === el.value ? '#b83434' : ''}`}}
                >{el.name}</th>
                )}
            </tr>
            </thead>
            <tbody>
            {list?.map(renderList)}
            </tbody>
        </BootstrapTable>
    </div>
  )
}

export default Table