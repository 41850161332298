import React, { useEffect } from 'react'
import styles from './styles.module.css'
import header_logo from '../../assets/images/header_logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { signOut } from '../../actions/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { version } from '../../assets/const'


export default function Layout({children}) {
  
  const {token} = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const {pathname} = useLocation() 
  const navigate = useNavigate()


  useEffect(() => {
    const notAuthLocation = pathname !== '/'
    !token && notAuthLocation && navigate('/', {state: { pathname }})
  }, [pathname, token])

  const exit = () => {
    dispatch(signOut())
  }

  return (
    <div className={styles.wrapper}>
        <header>
            <div className={styles.headContent}>
                <div>
                    <div className={styles.ministry}>
                        Управление военного образования Главного управления кадров
                        Министерства обороны Российской Федерации
                    </div>
                    <div className={styles.library}>
                        <div>СИСТЕМА АТТЕСТАЦИИ ПЕДАГОГИЧЕСКИХ РАБОТНИКОВ</div>
                        <div className={styles.subHeader}>довузовских общеобразовательных организаций <br/>Минобороны России</div>
                        {token && <div className={styles.exit} onClick={exit}>выход</div>}
                    </div>
                </div>
                <img src={header_logo} alt='o_O'/>
            </div>
        </header>
        <main>{children}</main>
        <footer>
            <div className={styles.version}>{version}</div>
            © 2023 ООО Нинтегра
        </footer>
    </div>
  )
}
