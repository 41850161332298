import jsPDF from "jspdf"
// import addFont from "../../../../fonts/fonts"
import 'jspdf-autotable'
import moment from "moment"
import addFont from "../../../../../assets/fonts/fonts"

import mo from '../../../../../assets/images/pdfLogos/MO.png'
import lms from '../../../../../assets/images/pdfLogos/LMS.png'
import guk from '../../../../../assets/images/pdfLogos/GUK.png'

const orientation = 'l'

export const getQuantityReferenceReport = async ({data, comission, userInfo}) => {

  let lastY = 35
  
  const {last_name, first_name, patronymic} = userInfo
  const createrName = `${last_name || ''} ${first_name || ''} ${patronymic || ''}`

  const getY = (indent, newValue) => {
    let newY = newValue ? newValue + indent : lastY + indent
    if (newY > doc.internal.pageSize.height - 15) {
      doc.addPage('a4', orientation)
      newY = 35
    }
    lastY = newY
    return newY
  }

  const addLogos = () => {
    const pageCount = doc.internal.getNumberOfPages()
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      
      doc.addImage(guk, 'PNG', 10, 5, 20, 25)
      doc.addImage(mo, 'PNG', 140, 3, 20, 12)
      doc.addImage(lms, 'PNG', 280, 199, 15, 9)

      doc.setFontSize(9)
      doc.text('Министерство обороны Российской Федерации', 150, 20, 'center')
      doc.text('Управление военного образования Главного управления кадров', 150, 25, 'center')

      doc.setFontSize(8)
      doc.text(`Кто создал: ${createrName || ''}`, 10, 202)
      doc.text(`Дата: ${moment(comission.date_to).format('DD.MM.YYYY')}`, 10, 205)
      doc.text(`Лист ${i} из ${pageCount}`, 141, 205)
      doc.text('Документ сформирован в системе управления обучением', 190, 205)

    }
  }
  
  addFont(jsPDF.API)
  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
  doc.setFont('Montserrat')

  doc.text('СПРАВКА', 150, lastY, 'center')
  doc.text('по общему количеству и результатам экспертизы аттестационных материалов (портфолио)', 150, getY(9), 'center')
  doc.text('педагогических работников ДОУ МО РФ', 150, getY(8), 'center')
  doc.text(`${moment().format('MMMM')} ${moment().year()}`, 150, getY(8), 'center')

  doc.autoTable({
    html: `#quantityReferenceTable`,
    styles: { font: 'Montserrat', fontSize: 8 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.2,
      lineColor: 100,
      fontSize: 8,
    },
    bodyStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.2,
      lineColor: 100,
      fontSize: 8,
      textColor: '#000',
    },
    margin: { top: 35 },
    startY: 65
  })

  doc.text('Руководитель экспертной группы                   Близнова Н.А.', 20, getY(15, doc.autoTableEndPosY()))

  addLogos()

  doc.addPage('a4', orientation)
  doc.deletePage(doc.getNumberOfPages())
  return doc
}