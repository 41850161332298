export const strip = (html) => {
    var tmp = document.createElement("div");
    tmp.innerHTML = html || '';
    return tmp.textContent || tmp.innerText;
}


export const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const getInitials = (name) => {
  if (!name) return '';
  const [lastName, firstName, patronymic] = name.split(' ')
  return `${firstName[0]}. ${patronymic[0]}. ${lastName}`
}