import { BACKEND_URL_PREFIX } from "../assets/const";
import { buildDefaultHeaders } from "./helper";
import moment from 'moment'
import { AUTH_COMPLETED, SET_AUTH_LOADING, SET_AUTH_USER_INFO, SET_AUTH_USER_PHOTO, SET_COMISSION_DATES, SET_ORGANIZATIONS, SIGNED_OUT } from "./types";
import { createNotification } from "../components/Notifications/Notifications";
import { blobToBase64 } from "../utils/smallHelpers";

const TOKEN_UPDATING_INTERVAL = 190;

const getTimeToExpireToken = (getState) => {
  const { valid_until } = getState().auth;
  return moment(valid_until).diff(moment.utc()) / 1000 / 60;
};

export const checkAndUpdateToken = () => async (dispatch, getState) => {
  const timeToExpire = getTimeToExpireToken(getState);
  if (timeToExpire < TOKEN_UPDATING_INTERVAL) {
    try {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/token`,
        buildDefaultHeaders(getState)
        );
      if (response.ok) {
        const result = await response.json();
        if (result.error && result.error.code === 401) {
          dispatch(signOut())
        } else {
          dispatch({ type: AUTH_COMPLETED, user: result });
        }
      } else {
        dispatch(signOut())
      }
    } catch (ERR) {
      dispatch(signOut())
    }
  }
};

export const auth = (authString) => async (dispatch, getState) => {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    try {
      
      const token = window.btoa(unescape(encodeURIComponent(authString)));
      const Authorization = `Basic ${token}`;
  
      const response = await fetch(`${BACKEND_URL_PREFIX}/token`, {
        headers: {
          Authorization,
          "Access-Control-Allow-Origin": "*",
        },
      });
  
      if (response.ok) {
        const result = await response.json();
        if (result.error && result.error.code === 401) {
          createNotification("error", "Неверное имя пользователя или пароль");
        } else {
          dispatch({ type: AUTH_COMPLETED, user: {...result, first_token: Authorization} });
          dispatch(getUserInfo())
          dispatch(getComissionDates())
          if (!result.expert) {
            dispatch(getUserPhoto())
          }
        }
      } else if (response.status === 401) {
          createNotification("error", "Неверное имя пользователя или пароль");
      }
    } catch (ERROR) {
      createNotification("error", "Сбой авторизации");
    } finally {
      dispatch({ type: SET_AUTH_LOADING, payload: false });
    }
  };

export const signOut = () => async (dispatch) => {
  dispatch({ type: SIGNED_OUT });
  // window.location.href = "/";
};

export const getUserInfo = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/certification/request/get_info`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_AUTH_USER_INFO, payload });
    }
  }
};

export const getUserPhoto = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/user/photo`, {
      method: "GET",
      cache: 'no-store',
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const blob = await response.blob()
      const base64 = await blobToBase64(blob)
      dispatch({ type: SET_AUTH_USER_PHOTO, payload: base64 });
    }
  }
};

export const getOrganizations = () => async (dispatch, getState) => {
  const response = await fetch(`${BACKEND_URL_PREFIX}/xp_organizations`, {
    method: "GET",
    // ...buildDefaultHeaders(getState),
  });
  if (response.ok) {
    const data = await response.json();
    const payload = data;
    dispatch({ type: SET_ORGANIZATIONS, payload });
  }
};

export const getComissionDates = () => async (dispatch, getState) => {
  const params = {year: moment().year()}
  const urlParams = new URLSearchParams(params).toString();
  const response = await fetch(`${BACKEND_URL_PREFIX}/certification/reports/dates?${urlParams}`, {
    method: "GET",
    ...buildDefaultHeaders(getState),
  });
  if (response.ok) {
    const payload = await response.json();
    dispatch({ type: SET_COMISSION_DATES, payload });
  }
};