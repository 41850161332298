import React, { useState } from 'react'
import styles from './styles.module.css'

import LoginForm from './LoginForm'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { auth, getOrganizations } from '../../actions/auth'
import { useLocation, useNavigate } from 'react-router-dom'

export default function Auth() {
  
  const dispatch = useDispatch()
  const { organizations: orgList, token, expert, edu_expert, loading } = useSelector(state => state.auth)
  const navigate = useNavigate()
  const {state} = useLocation()

  const [authMode, setAuthMode] = useState('')

  useEffect(() => {
    !orgList.length && dispatch(getOrganizations())
  }, [])

  useEffect(() => {
    if (token) {
      const route = `/${(expert || edu_expert) ? 'expert' : 'teacher'}`
      navigate(route)
    }
  }, [token])
  
  

  const onSubmit = ({ username, password, organization }) => {
    const orgId = organization ? `${organization}|` : ''
    const expertMode = authMode === 'eoexpert' ? `|expert` : ''
    const authString =`${orgId}${username.trim()}${expertMode}:${password.trim()}`;
    dispatch(auth(authString))
  }
    
  if (token) return null;

  return (
    <div className={styles.container}>
      {authMode
        ? <div className={styles.formContainer}>
            <div className={styles.label}>
                Пожалуйста, авторизуйтесь                        
            </div>
            <div className={styles.iconContainer}>
                <div className={styles.userIcon} />
            </div>
            <LoginForm
              teacherMode={['teacher', 'eoexpert'].includes(authMode)}
              onSubmit={onSubmit}
              orgList={orgList}
              loading={loading}
            />
          </div>
        : <div className={styles.authButtons}>
            <button onClick={() => setAuthMode('teacher')}>Педагогический работник</button>
            <button onClick={() => setAuthMode('eoexpert')}>Комиссия ОО</button>
            <button onClick={() => setAuthMode('expert')}>Эксперт</button>
          </div>
      }
    </div>
  )
}
