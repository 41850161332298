import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { getCurrentRequest } from "./common";
import { buildDefaultHeaders } from "./helper";
import { updateRequestStatus } from "./teacher";
import { SET_EXPERT_FORM, SET_EXPERT_LOADING } from "./types";


export const setExpertForm = (payload) => ({type: SET_EXPERT_FORM, payload})

export const setExpertGrade = (requestId, data, files) => async (dispatch, getState) => {
    dispatch({type: SET_EXPERT_LOADING, payload: true})
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        let returnState
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/certification/request/${requestId}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                
                const initialFiles = getState().common.currentRequest.expert_files_json
                const fileAdded = files.length && !files[0].hasOwnProperty('file_id')

                initialFiles?.length && (!files.length || fileAdded) && await dispatch(deleteFile(requestId))
                fileAdded && await dispatch(uploadFile(requestId, files[0]))
                
                await dispatch(updateRequestStatus(requestId, 6))
                await dispatch(getCurrentRequest(requestId))
                
                createNotification('success', 'Заявление оценено успешно')
                returnState = true
            }
        } catch (ERR) {
            createNotification('error', 'Ошибка сохранения оценки')
            returnState = false
        } finally {
            dispatch({type: SET_EXPERT_LOADING, payload: false})
            return returnState
        }
    }
};


export const uploadFile = (requestId, file, resumeIndex) => async (dispatch, getState) => {
  
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
  
      const fd = new FormData();
      fd.append('file', file)

      const response = await fetch(
        `${BACKEND_URL_PREFIX}/certification/request/${requestId}/expert_file`,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd
        }
      );
      if (response.ok) {
      }
    }  
};

export const deleteFile = (requestId, resumeIndex) => async (dispatch, getState) => {
  
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

      const response = await fetch(
        `${BACKEND_URL_PREFIX}/certification/request/${requestId}/expert_file`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState)
        }
      );
      if (response.ok) {
      }
   }  
};

