import * as types from "../actions/types";

const initialState = {
    list: null,
    data: null,
    loading: false,
};


export default function reports (state = initialState, action) {
  switch (action.type) {
    case types.SET_REPORTS_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case types.SET_REPORT_DATA:
      return {
        ...state,
        data: action.payload,
        name: action.reportName
      };
    case types.SET_REPORTS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}