import React from 'react'
import styles from './Search.module.css'
import Button from '../../../components/Button'
import { parallels } from '../../../assets/const'
import { getYearsOptions } from '../../../utils/getOptions'
import { Form, Row } from 'react-bootstrap'

const Search = ({
    values,
    handleChange,
    organizations,
    handleSubmit,
    lessons
}) => {

    const renderLessonslOption = ({LID, name}) => <option key={LID} value={LID}>{name}</option>;
    const renderOrganizationlOption = ({idOrganization, OrgName}) => <option key={idOrganization} value={idOrganization}>{OrgName}</option>;
    const renderParallelOption = (num) => <option key={num} value={num}>{num}</option>;

  return (
    <>
    <div className={styles.wrapperTop}>
        <Row className={styles.input_search}>
            <Form.Group>
            <Form.Label className={styles.formLabels}>Выберите образовательную организацию:</Form.Label>
            <Form.Select
                name='organization'
                value={values.organization}
                onChange={handleChange}>
                <option value=''>Все</option>
                {organizations?.map(renderOrganizationlOption)}
            </Form.Select>
            </Form.Group>
        </Row>
        <Button className={styles.buttonSearch} type='submit' label={"Поиск"} onClick={() => handleSubmit()}/>
    </div>
            
    <div className={styles.wrapper_selects}>
        <Row className={styles.input_lessons}>
            <Form.Group>
            <Form.Label className={styles.formLabels}>Выберите дисциплину:</Form.Label>
            <Form.Select
                name='lesson_id'
                value={values.lesson_id}
                onChange={handleChange}>
                <option value=''>Все</option>
                {lessons?.map(renderLessonslOption)}
            </Form.Select>
            </Form.Group>
        </Row>
        <Row className={styles.input_parallel}>
            <Form.Group>
            <Form.Label className={styles.formLabels}>Выберите параллель:</Form.Label>
            <Form.Select
                name='parallel'
                value={values.parallel}
                onChange={handleChange}>
                <option value=''>Все</option>
                {parallels?.map(renderParallelOption)}
            </Form.Select>
            </Form.Group>
        </Row>
        <Row className={styles.input_year}>
            <Form.Group>
            <Form.Label className={styles.formLabels}>Выберите год:</Form.Label>
            <Form.Select
                name='year'
                value={values.year}
                onChange={handleChange}>
                <option value=''>Все</option>
                {getYearsOptions(5)}
            </Form.Select>
            </Form.Group>
        </Row>
        <Row className={styles.input_filename}>
            <Form.Group className={styles.inputBlock}>
            <Form.Label className={styles.formLabels}>Поиск по названию файла:</Form.Label>
            <input
            name='filename'
            placeholder='начните вводить текст...'
            value={values.filename}
            onChange={handleChange}/>
            </Form.Group>
        </Row>
    </div>
    </>
  )
}

export default Search