import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import RequestForm from './Components/RequestForm'
import RequestsTable from './Components/RequestsTable'
import { useDispatch, useSelector } from 'react-redux'
import { getSpecializations, getRequests, removeRequestsList } from '../../actions/common'
import { getOrganizations } from '../../actions/auth'
import Loader from '../../components/Loader'
import { useNavigate } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import Button from '../../components/Button'
import Reports from './Components/Reports'



export default function Expert() {
  
  const { specializations, requestsList, loading } = useSelector(state => state.common)
  const { organizations, expert, org } = useSelector(state => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  useEffect(() => {
    !specializations && dispatch(getSpecializations())
    !organizations && dispatch(getOrganizations())
  }, [])

  const getRequestsList = (filters) => {
    dispatch(getRequests(filters))
  }
  
  const openRequest = (requestData) => () => {
    navigate(`/request/${requestData.id}`)
  }

  const sharedFilesClick = () => {
    navigate(`/shared_files`)
  }

  return (
    <div className={styles.container}>
      <Col className={styles.butttonsBlock}>
        <Button label={"Методическая копилка"} onClick={sharedFilesClick}/>
        {expert && <Reports/>}
      </Col>
      <RequestForm
        specializations={specializations}
        org={org}
        expert={expert}
        organizations={organizations}
        getRequestsList={getRequestsList}
      />
      {loading
          ? <Loader/>
          : !!requestsList && 
              <RequestsTable
                specializations={specializations}
                data={requestsList}
                expert={expert}
                openRequest={openRequest}
              />
      }
    </div>
  )
}
