import React, { useState } from 'react'
import styles from '../styles.module.css'
import ReactQuill from 'react-quill';
import { Col, Form, Row, Table } from 'react-bootstrap';
import Button from '../../../components/Button';
import FileInput from '../../../components/FileInput/FileInput';
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik';
import { criterionFormValidationSchema } from '../../../assets/validation';
import cn from 'classnames';
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { sendCriterion, finishRequest, updateCriterion } from '../../../actions/teacher';
import { strip } from '../../../utils/smallHelpers'
import { removeRequestData } from '../../../actions/common';
import FileLabel from '../../../components/FileLabel/FileLabel';
import { BACKEND_DOMAIN } from '../../../assets/const';
import FileBlock from '../../../components/FileBlock';
import ResponsibleBlock from '../../../components/ResponsibleBlock';
import Confirmation from '../../../components/Confirmation/Confirmation';

export default function NewCertificationCriterions({
  clearRequest,
  setInfoActiveMode,
}) {
  
  const [files, setFiles] = useState([])
  const [responsible, setResponsible] = useState([])
  const [finishRequestConfirm, setFinishRequestConfirm] = useState(false)

  const dispatch = useDispatch()
  const { criterions, requestParts, currentRequest } = useSelector(state => state.common)


  const handleAddFile = (e) => {
    const { files } = e.target
    const filesList = Object.values(files)
    setFiles(prev => [...prev, ...filesList])
  }

  const onDeleteFile = (name, index) => {
    const filesList = [...files]
    filesList.splice(index, 1)
    setFiles(filesList)
  }

  const completeButtonClick = () => {
    setFinishRequestConfirm(true)
  }
  
  const acceptFinishConfirm = async () => {
    setFinishRequestConfirm(false)
    await dispatch(finishRequest({files, responsible}))
    dispatch(removeRequestData())
    setInfoActiveMode(false)
    clearRequest()
  }
    
  const rejectFinishConfirm = () => {
    setFinishRequestConfirm(false)
  }

  const saveDraftClick = async () => {
    await dispatch(finishRequest({files, responsible, draft: true}))
    dispatch(removeRequestData())
    clearRequest()
  }

  const renderCriterionsGroup = ({name, criterions}, index) => {
    const groupName = `${index+1}. ${name}`
    return (
      <>
        <tr>
          <td className={styles.titleTd} colSpan={6}><b>{groupName}</b></td>
        </tr>
        {criterions.map(renderCriterion())}
      </>
    )
  }

  const renderCriterion = (documentsDescription) => (criterion, ind) => {
    const {id, name, documents, points, level_json, child_criterion_json} = criterion
    const currentCriterion = requestParts?.find(part => part.criteria === id)
    const emptyCriterion = { name, criteria: id }
    return (
      <>
        <CriterionItem
          key={ind}
          index={ind}
          requestId={currentRequest.id}
          criterion={currentCriterion || emptyCriterion}
          criteriaName={name}
          validPoints={points}
          criteriaDocuments={documents || documentsDescription}
          criteriaLevels={level_json}
          empty={!currentCriterion}
          subCriterion={!child_criterion_json}
          haveChildren={!!child_criterion_json?.length}
          childCriterions={child_criterion_json}
        />
        {child_criterion_json?.map(renderCriterion(documents))}
      </>
    )
  }

  const criterionsLength = criterions?.reduce((acc, crit) => acc + crit.criterions.reduce((acc, crit) => acc + (crit.child_criterion_json.length || 0) + 1, 0), 0)
  const sendButtonDisabled = criterionsLength > requestParts?.length

  return (
    <div className={styles.criterionsWrapper}>
      
      <h3 className={styles.formBlockTitle}>Введите информацию по критериям:</h3>
      <b>(все подтверждающие документы в соответствии с требованиями экспертного заключения по занимаемой должности загружать в полном объеме)</b>

      <Table striped>
        <thead>
          <tr>
            {/* <th>№</th> */}
            <th>Критерии и показатели</th>
            <th>Наличие подтверждающих документов в портфолио</th>
            <th>Файлы</th>
            <th>Примечание</th>
            <th>Балллы комиссии ОО</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {criterions?.map(renderCriterionsGroup)}
        </tbody>
      </Table>

      {/* <div className={styles.portfolioFiles}>
        <h3 className={styles.formBlockTitle}>Файлы портфолио:</h3>
        <FileInput
          files={files}
          onChange={handleAddFile}
          onDelete={onDeleteFile}
          height='300px'
          width='60%'
          multiple
        />
      </div> */}

      {/* <div>
        <h3 className={styles.formBlockTitle}>Члены комиссии ОО:</h3>
        <ResponsibleBlock data={responsible} setData={setResponsible} />
      </div> */}

      {finishRequestConfirm &&
        <Confirmation
          title='Вы уверены что хотите отправить заявление?'
          acceptConfirmation={acceptFinishConfirm}
          rejectConfirmation={rejectFinishConfirm}
        />
      }

      <div className={styles.criterionButtons}>
        <Button
          label='Подписать и отправить'
          onClick={completeButtonClick}
          // disabled={sendButtonDisabled}
        />
        <Button onClick={saveDraftClick} label='Сохранить черновик и выйти'/>
      </div>
    </div>
  )
}


const CriterionItem = ({
  index,
  criterion,
  requestId,
  validPoints,
  criteriaName,
  criteriaDocuments,
  criteriaLevels,
  subCriterion,
  empty,
  haveChildren,
  childCriterions
}) => {
  
  const dispatch = useDispatch()
  const { formLoading: loading } = useSelector(state => state.teacher)

  const [isTouched, setIsTouched] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [quillError, setQuillError] = useState({documents: false, description: false})

  const {
    values,
    errors,
    touched,
    isValid,
    setTouched,
    setFieldValue,
    handleChange,
    handleSubmit,
    handleBlur
  } = useFormik({
    initialValues: {...criterion, files: criterion.files_json || []},
    enableReinitialize: true,
    validationSchema: criterionFormValidationSchema,
    validateOnMount: true,
    onSubmit: values => values.id ? handleUpdateCriterion(values) : handleSendCriterion(values)
  })
  
  const handleSendCriterion = async (values) => {
    const documentsError = !strip(values.documents)
    if (documentsError) {
      setQuillError(prev => ({...prev, documents: true}))
      return
    }
    const response = await dispatch(sendCriterion(values, requestId))
    if (response) {
      setIsOpen(false)
    }
  }
  
  const handleUpdateCriterion = async ({id, ...values}) => {
    const documentsError = !strip(values.documents)
    if (documentsError) {
      setQuillError(prev => ({...prev, documents: true}))
      return
    }
    const response = await dispatch(updateCriterion(values, requestId, id))
    if (response) {
      setIsOpen(false)
    }
  }

  const openModal = () => {
    if (haveChildren) return;
    setIsOpen(true)
    setIsTouched(true)
  }
  
  const closeModal = () => {
    setIsOpen(false)
  }

  const handleAddFile = (fileObject, choiceMode) => {
    const {lesson_id, parallel, file} = fileObject
    if (!file?.length && !choiceMode) return;

    let newFile
    const existFiles = values.files || []

    if (choiceMode) {
      newFile = fileObject
    } else {
      const filename = file[0].name
      newFile = {file: file[0], filename, lesson_id, parallel}

    }

    const filesArr  = [...existFiles, newFile]
    const filesValue = filesArr.length ? filesArr : null
    setFieldValue('files', filesValue)

  }
  
  const handleSetTouched = (name) => {
    setTouched({...touched, [name]: true})
  }

  const onDelete = (name, index) => {
    const filesList = [...values[name]]
    filesList.splice(index, 1)
    const filesValue = filesList.length ? filesList : null
    setFieldValue(name, filesValue)
  }

  const handleQuillChange = (name, value) => {
    setFieldValue(name, value)
    const errorValue = !strip(value)
    setQuillError(prev => ({...prev, [name]: errorValue}))
  }

  const renderIcon = () => {
    if (isTouched && !isOpen && !empty) return <AiOutlineCheck size={30} color='#1ad91a'/>
    if (isTouched && !isOpen && empty) return <AiOutlineClose size={30} color='red'/>
  }


  const renderFile = file => {
    const {filepath, filename, mimetype} = file
    return (
      <FileLabel
        fileName={filename}
        imageMode={mimetype?.includes('image')}
        fileLink={`${BACKEND_DOMAIN||''}/${filepath}/${filename}`}
      />
    )
  }

  const criterionNameStyle = {paddingLeft: subCriterion ? '40px' : '0'}

  const criterionDescription = criteriaDocuments ? `(${criteriaDocuments})` : ''

  return (
    <>
      <tr key={index} onClick={openModal} className={cn({[styles.criterionWithChild]: haveChildren})}>
        {/* <td>{index+1}</td> */}
        <td style={criterionNameStyle}>{criteriaName}</td>
        <td>{strip(criterion.documents)}</td>
        <td className={styles.filesTd}>
          <div>{criterion.files_json?.map(renderFile)}</div>
        </td>
        <td>{strip(criterion.description)}</td>
        <td>{criterion.commission_points}</td>
        <td><i className={styles.icon}>{renderIcon()}</i></td>
      </tr>
      <Modal
        open={isOpen}
        onClose={closeModal}
        classNames={{ modal: styles.criterionModal }}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        showCloseIcon={false}
        animationDuration={1}
      >
          <h4>{criteriaName}</h4>
          <div className={styles.criterionDescription}>{criterionDescription}</div>
          <Form className='mt-3'>
            {!!criteriaLevels?.length &&
            <Row className='mb-3'>
              
              <Form.Group>
                <Form.Label className={styles.formLabels}>Выберите опцию:</Form.Label>
                <Form.Select
                  name='level'
                  value={values.level}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.level && errors.level}
                  // disabled={sended}
                >
                  <option value=''>Выбрать</option>
                  {criteriaLevels.map(level =>
                    <option key={level.level_id} value={level.level_id}>{level.level_name}</option>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.cert_year}</Form.Control.Feedback>
              </Form.Group>

            </Row>}
            <Row className='mb-3'>
              <Form.Label className={cn(styles.formLabels, {
                [styles.errorLabel]: quillError.documents
              })}>
                Наличие подтверждающих документов в портфолио:
              </Form.Label>
              <ReactQuill
                // readOnly={sended}
                className={cn(styles.quill, {
                  [styles.error]: quillError.documents
                })}
                value={values.documents}
                onChange={value => handleQuillChange('documents', value)}
              />
            </Row>
            <Row className='mb-3'>
              <FileBlock
                choiceButtonVisible={criterion.criteria === 692 || criterion.criteria === 1}
                filesList={values.files}
                onDelete={onDelete}
                addFile={handleAddFile}
              />
              {/* <Form.Label className={styles.formLabels}>Файлы (привязка нескольких файлов):</Form.Label>
              <FileInput
                height='300px'
                name='files'
                files={values.files}
                onChange={handleAddFile}
                onBlur={handleBlur}
                isInvalid={touched.files && errors.files}
                onDelete={onDelete}
                setTouched={handleSetTouched}
                // disabled={sended}
                multiple
              /> */}
            </Row>
            <Row className='mb-3'>
              <Form.Label className={styles.formLabels}>Примечание:</Form.Label>
              <ReactQuill
                // readOnly={sended}
                className={styles.quill}
                value={values.description}
                onChange={value => handleQuillChange('description', value)}
              />
            </Row>
            {/* <Row className='mt-3'>
              <Form.Group as={Col} md={3}>
                <Form.Label className={styles.formLabels}>Баллы комиссии ОО:</Form.Label>
                <Form.Control
                  name='commission_points'
                  type='number'
                  value={values.commission_points}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // isInvalid={touched.commission_points && errors.commission_points}
                />
                <Form.Control.Feedback type="invalid">{errors.points}</Form.Control.Feedback>
              </Form.Group>
            </Row> */}
            {/* <p className={styles.validPoints}>Допустимое количество баллов по критерию - {validPoints}</p> */}
            <div className={styles.criterionButtons}>
              <Button
                type='button'
                label='Сохранить'
                onClick={handleSubmit}
                // disabled={!isValid || sended}
                submitting={loading}
              />
              <Button
                type='button'
                label='Отмена'
                onClick={closeModal}
              />
            </div>
          </Form>
      </Modal>
    </>
  )
}