import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../Button'
import { Modal } from 'react-responsive-modal'
import { Form, Row, Table } from 'react-bootstrap'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { IoMdClose } from 'react-icons/io'


export default function ResponsibleBlock ({data, setData, disabled}) {
  
  
  const [openAddModal, setOpenAddModal] = useState(null)
  const [editModeIndex, setEditModeIndex] = useState(null)


  const openModal = (data) => () => setOpenAddModal(data)
  const closeModal = () => setOpenAddModal(null)

  const rowClick = (data, index) => () => {
    if (disabled) return;
    openModal(data)()
    setEditModeIndex(index)
  }

  const renderRow = (resp, index) => {
    return (
      <tr onClick={rowClick(resp, index)}>
        <td>{resp.position}</td>
        <td>{resp.name}</td>
        {!disabled && <td onClick={handleDeleteRow(index)}><IoMdClose color='red' size={20}/></td>}
      </tr>
    )
  }

  const handleAddRow = (resp) => {
    setData(prev => [...prev, resp])
  }
  
  const handleEditRow = ({id, ...resp}) => {
    const prevData = [...data]
    prevData.splice(editModeIndex, 1, resp)
    setData(prevData)
  }
  
  const handleDeleteRow = (index) => (e) => {
    e.stopPropagation()
    setData(prev => prev.filter((_, i) => i !== index))
  }

  return (
    <div className={styles.wrapper}>
      {data.length
        ? <Table>
            <thead>
              <tr>
                <th>Должность</th>
                <th>ФИО</th>
                {!disabled && <th></th>}
              </tr>
            </thead>
            <tbody>
              {data.map(renderRow)}
            </tbody>
          </Table>
        : <p className={styles.noData}>Нет данных</p>  
    }
      
      <AddModal
        modalData={openAddModal}
        editMode={editModeIndex !== null}
        closeModal={closeModal}
        editRow={handleEditRow}
        addRow={handleAddRow}
      />
      {!disabled && <Button onClick={openModal({})} label="Добавить"/>}
    </div>
  )
}



const AddModal = ({ modalData, editMode, closeModal, addRow, editRow }) => {

  useEffect(() => {
    if (!!modalData) {
      editMode && setValues({...modalData})
      !editMode && handleReset()
    }
  }, [modalData])
  

  const {
    values,
    handleChange,
    handleSubmit,
    handleReset,
    setValues
  } = useFormik({
    initialValues: { position: '', name: ''},
    enableReinitialize: true,
    onSubmit: values => {
      editMode ? editRow(values) : addRow(values)
      closeModal()
    }
  })  

  const submitButtonLabel = editMode ? 'Изменить' : 'Добавить'

  return (
    <Modal
      open={!!modalData}
      onClose={closeModal}
      animationDuration={1}
      classNames={{modal: styles.modal}}
    >
      <Form className='mt-3'>
        
        <Row className='mb-3'>
          <Form.Group>
            <Form.Label className={styles.formLabels}>Должность:</Form.Label>
            <Form.Control
              name='position'
              value={values.position}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        
        <Row className='mb-3'>
          <Form.Group>
            <Form.Label className={styles.formLabels}>ФИО:</Form.Label>
            <Form.Control
              name='name'
              value={values.name}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        
        <div className={styles.buttonsBlock}>
          <Button type='button' onClick={handleSubmit} label={submitButtonLabel}/>
          <Button type='button' onClick={closeModal} label='Отмена'/>
        </div>
      </Form>
    </Modal>
  )
}