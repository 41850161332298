import jsPDF from "jspdf"
// import addFont from "../../../../fonts/fonts"
import 'jspdf-autotable'
import moment from "moment"
import addFont from "../../../../../assets/fonts/fonts"
import {htmlToText} from 'html-to-text'
import { MONTH_GENITIVE } from "../../../../../assets/const"

import mo from '../../../../../assets/images/pdfLogos/MO.png'
import lms from '../../../../../assets/images/pdfLogos/LMS.png'
import guk from '../../../../../assets/images/pdfLogos/GUK.png'

const orientation = 'p'

export const getProtocol = async ({data, comission, userInfo}) => {

  const { all_requests, failure_all, first_all, first_failure, first_success, high_all, high_failure, high_success, success_all } = data?.teachers_statistic || {}

  const {last_name, first_name, patronymic} = userInfo
  const createrName = `${last_name || ''} ${first_name || ''} ${patronymic || ''}`

  let lastY = 35
  
  const getY = (indent, newValue) => {
    let newY = newValue ? newValue + indent : lastY + indent
    if (newY > doc.internal.pageSize.height - 15) {
      doc.addPage('a4', orientation)
      newY = 35
    }
    lastY = newY
    return newY
  }

  const addLogos = () => {
    const pageCount = doc.internal.getNumberOfPages()
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      
      doc.addImage(guk, 'PNG', 10, 5, 20, 25)
      doc.addImage(mo, 'PNG', 95, 3, 20, 12)
      doc.addImage(lms, 'PNG', 190, 285, 15, 9)

      doc.setFontSize(9)
      doc.text('Министерство обороны Российской Федерации', 105, 20, 'center')
      doc.text('Управление военного образования Главного управления кадров', 105, 25, 'center')

      doc.setFontSize(8)
      doc.text(`Кто создал: ${createrName || ''}`, 10, 292)
      doc.text(`Дата: ${moment(comission.date_to).format('DD.MM.YYYY')}`, 10, 295)
      doc.text(`Лист ${i} из ${pageCount}`, 100, 295, 'center')
      doc.text('Документ сформирован в системе управления обучением', 100, 290)

    }
  }

  const dateFormatted = (date) => moment(date).format(`DD ${MONTH_GENITIVE[moment(date).month()]} YYYY`)

  const comissionPeriod = `${dateFormatted(comission?.date_from)} - ${dateFormatted(comission?.date_to)}`

  addFont(jsPDF.API)
  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })

  doc.setFont('Montserrat', 'bold')
  doc.setFontSize(13)
  
  
  doc.text('Протокол № 2', 105, lastY, 'center')
  doc.text('заседания экспертной группы Аттестационной комиссии УВО ГУК', 105, getY(7), 'center')
  doc.text('Министерства обороны Российской Федерации', 105, getY(7), 'center')
  doc.text(`от ${dateFormatted(comission?.date_to)} года`, 105, getY(7), 'center')
  
  doc.setFontSize(12)
  doc.setFont(undefined, 'normal')
  
  doc.text(`Период работы экспертной группы: ${comissionPeriod}`, 23, getY(14))
  doc.text(`Присутствовали:  Близнова Н.А., руководитель экспертной группы,  члены`, 23, getY(8))
  doc.text(`ЭГ: Кузнецова М.С., Данилина Т.Н., Андреева В.Л., Глухарева О.В., `, 23, getY(8))
  doc.text(`Самсонова О.В., Нескина Н.Б., Земскова Д. Д., Фоменкова Т.А.`, 23, getY(8))
  
  doc.setFont(undefined, 'bold')
  doc.text(`Повестка дня:`, 37, getY(9))
  
  doc.setFont(undefined, 'normal')
  doc.text(`1. Рассмотрение аттестационных материалов педагогических`, 30, getY(9))
  doc.text(`работников с целью установления соответствия`, 37, getY(8)).setFont(undefined, 'bold').text('первой', 146, lastY).setFont(undefined, 'normal')
  doc.text(`квалификационной категории.`, 37, getY(8))
  doc.text(`2. Рассмотрение аттестационных материалов педагогических `, 30, getY(8))
  doc.text(`работников с целью установления соответствия`, 37, getY(8)).setFont(undefined, 'bold').text('высшей', 146, lastY).setFont(undefined, 'normal')
  doc.text(`квалификационной категории.`, 37, getY(8))

  doc.setFont(undefined, 'bold')
  doc.text(`Ход заседания:`, 37, getY(9))
  
  doc.setFont(undefined, 'normal')
  doc.text(`Всего представлено портфолио - ${all_requests || 0}`, 37, getY(9))
  doc.text(`По результатам экспертизы подтверждено - ${success_all || 0}`, 37, getY(8))
  doc.text(`Не соответствуют занимаемой должности - ${failure_all || 0}`, 37, getY(8))

  doc.text('1.  Количество представленных аттестационных материалов', 37, getY(8))
  doc.text('(портфолио профессиональной деятельности) для  установления', 23, getY(8)).setFont(undefined, 'bold').text('первой', 171, lastY).setFont(undefined, 'normal')
  doc.text(`квалификационной категории – ${first_all || 0}`, 23, getY(8))
  doc.text(`По результатам экспертизы подтверждено – ${first_success || 0}`, 37, getY(8))
  doc.text(`Не соответствуют занимаемой должности – ${first_failure || 0}`, 37, getY(8))

  doc.text('2.  Количество представленных аттестационных материалов', 37, getY(8))
  doc.text('(портфолио профессиональной деятельности) для  установления', 23, getY(8)).setFont(undefined, 'bold').text('высшей', 171, lastY).setFont(undefined, 'normal')
  doc.text(`квалификационной категории – ${high_all || 0}`, 23, getY(8))
  doc.text(`По результатам экспертизы подтверждено – ${high_success || 0}`, 37, getY(8))
  doc.text(`Не соответствуют занимаемой должности – ${high_failure || 0}`, 37, getY(8))

  data?.teachers_reasons?.forEach((teacher, index) => {
    const { OrgName, firstName, lastName, patronymic, reason } = teacher

    doc.addPage('a4', orientation)

    doc.setFont(undefined, 'bold').setFontSize(13)
    doc.text('Итоги заседания экспертной группы:', 105, getY(15, 35), 'center')
    doc.text(`${index+1}. ${OrgName.replace('\r\n', ' ')}`, 105, getY(7), 'center')
    doc.text(`${lastName || ''} ${firstName || ''} ${patronymic || ''}`, 105, getY(7), 'center')

    doc.setFont(undefined, 'normal')

    const plainText = htmlToText(reason, {
      wordwrap: false,
      uppercaseHeadings: false
    });

    doc.text(doc.splitTextToSize(plainText, 175), 17, getY(10))

  })

  addLogos()

  doc.addPage('a4', orientation)
  doc.deletePage(doc.getNumberOfPages())
  return doc
}