function recursivePointsSum(data) {
  let totalPoints = 0;
  
  if (Array.isArray(data)) {
      data.forEach(item => {
          totalPoints += recursivePointsSum(item);
      });
  } else if (typeof data === 'object' && data !== null) {
      if ('points' in data) {
          totalPoints += parseInt(data.points || 0);
      }
      if ('criterions' in data) {
          totalPoints += recursivePointsSum(data.criterions);
      }
      if ('child_criterion_json' in data) {
          totalPoints += recursivePointsSum(data.child_criterion_json);
      }
  }
  
  return totalPoints;
}

export const approved = (getState) => {
  const { criterions, requestParts, currentRequest } = getState().common
  const haveAward = currentRequest.awards?.find(({status}) => !!status)
  const simpleApproved = currentRequest.simple_cert && haveAward
  const pointsSum = requestParts.reduce((sum, part) => sum + (parseInt(part.points) || 0), 0)
  const neededPoints = recursivePointsSum(criterions)
  const approved = currentRequest.simple_cert ? simpleApproved : pointsSum >= neededPoints 
  return approved
}