import React, { useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import styles from '../styles.module.css'
import { useFormik } from 'formik'
import { getOptions, getOrganizationOptions, getYearsOptions } from '../../../utils/getOptions'
import { categories } from '../../../assets/const'
import { expertFormValidation } from '../../../assets/validation'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/Button'
import { useSelector } from 'react-redux'

export default function RequestForm({ specializations, organizations, getRequestsList, org, expert }) {
  
  const { form } = useSelector(state => state.expert)

  useEffect(() => {
    if (!form) return;
    getRequestsList(form)
    filters.setValues(form)
  }, [])
  

  const filters = useFormik({
    initialValues: {
      cert_year_from: '',
      cert_year_to: '',
      organization: org || '',
      position: '',
      category: '',
      simple_cert: 0
    },
    enableReinitialize: true,
    validationSchema: expertFormValidation,
    validateOnMount: true,
    onSubmit: values => getRequestsList(values)
  })

  const {values, errors, touched, handleChange, handleSubmit, handleBlur} = filters
  
  return (
    <div className={styles.requestForm}>
      <h3>Заявления на аттестацию педагогических работников</h3>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Form.Group className='mt-3' as={Col} md={3}>
            <Form.Label className={styles.formLabels}>Дата с:</Form.Label>
            <Form.Control
              name='cert_date_from'
              value={values.cert_date_from}
              type='date'
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.cert_date_from && errors.cert_date_from}
            />
            <Form.Control.Feedback type="invalid">{errors.cert_date_from}</Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group className='mt-3' as={Col} md={3}>
            <Form.Label className={styles.formLabels}>Дата по:</Form.Label>
            <Form.Control
              name='cert_date_to'
              value={values.cert_date_to}
              type='date'
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.cert_date_to && errors.cert_date_to}
            />
            <Form.Control.Feedback type="invalid">{errors.cert_date_to}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mt-3' as={Col} md={expert ? 12 : 6}>
            <Form.Label className={styles.formLabels}>Организация:</Form.Label>
            <Form.Select
              name='organization'
              value={values.organization}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!expert}
              isInvalid={touched.organization && errors.organization}
            >
              <option value=''>Выбрать</option>
              {getOrganizationOptions(organizations)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{errors.organization}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        
        {expert &&
          <Row>
            <Form.Group className='mt-3' as={Col}>
              <Form.Check
                label='аттестация педагогических работников, имеющих государственные награды, почетные звания, ведомственные знаки отличия, награды полученные за достижения в педагогической деятельности, награды конкурсов профессионального мастерства'
                name='simple_cert'
                onClick={() => filters.setFieldValue('simple_cert', values.simple_cert ? 0 : 1)}
                checked={!!values.simple_cert}
              />
            </Form.Group>
          </Row>
        }

        <Row style={{alignItems: 'end'}}>
          <Form.Group className='mt-3' as={Col} md={4}>
            <Form.Label className={styles.formLabels}>Должность:</Form.Label>
            <Form.Select
              name='position'
              value={values.position}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.position && errors.position}
            >
              <option value=''>Выбрать</option>
              {getOptions(specializations)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{errors.position}</Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group className='mt-3' as={Col} md={3}>
            <Form.Label className={styles.formLabels}>Категория:</Form.Label>
            <Form.Select
              name='category'
              value={values.category}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.category && errors.category}
            >
              <option value=''>Выбрать</option>
              {getOptions(categories)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{errors.category}</Form.Control.Feedback>
          </Form.Group>

          <Col className='d-flex align-items-end'>
            <Button label='Применить' type='submit'/>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
