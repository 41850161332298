import * as yup from 'yup'


export const firstFormValidationSchema = yup.object().shape({
  cert_year: yup.string().required('Поле является обязательным'),
  position: yup.string().required('Поле является обязательным'),
  category: yup.string().required('Поле является обязательным'),
  current_position: yup.string().required('Поле является обязательным'),
  cert_reason: yup.string().required('Поле является обязательным'),
  edu_exp: yup.number().required('Поле является обязательным'),
  position_exp: yup.number().required('Поле является обязательным'),
  org_exp: yup.number().required('Поле является обязательным'),
  // file_1: yup.array().required('Файл является обязательным'),
  // file_2: yup.array().required('Файл является обязательным')
})

export const requestFilesFormSchema = yup.object().shape({
  user_filename: yup.string().required('Поле является обязательным'),
  file: yup.array().required('Файл является обязательным'),
})

export const awardsFilesFormSchema = yup.object().shape({
  year: yup.string().required('Поле является обязательным'),
  user_filename: yup.string().required('Поле является обязательным'),
  file: yup.array().required('Файл является обязательным'),
})

export const criterionFormValidationSchema = yup.object().shape({
  files: yup.array().required('Файл является обязательным'),
})

export const expertFormValidation = yup.object().shape({
  cert_year_to: yup.number().min(yup.ref("cert_year_from"), "Дата завершения должна быть позже даты начала")
})