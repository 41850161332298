import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { getCriterions, getRequestParts, getRequests } from "./common";
import { buildDefaultHeaders } from "./helper";
import {
  SET_CURRENT_REQUEST,
  SET_TEACHER_FORM_LOADING,
  SET_TEACHER_LOADING
} from "./types";


export const sendRequest = (values) => async (dispatch, getState) => {
  
  const {cert_request_scan, cert_request_common, cert_request_award, ...data} = values

  dispatch({type: SET_TEACHER_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());

  if (getState().auth.token) {

    const fd = new FormData();
    for ( const key in data ) {
      fd.append(key, data[key]);
    }
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/certification/request`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        },
        body: fd
      }
    );

    const respData = await response.json()
    const request = respData.certification_request

    await Promise.all(cert_request_scan?.map(async (file) => await dispatch(uploadRequestFile(request.id, file))))
    await Promise.all(cert_request_common?.map(async (file) => await dispatch(uploadRequestFile(request.id, file))))
    await Promise.all(cert_request_award?.map(async (file) => await dispatch(uploadRequestFile(request.id, file))))

    if (response.ok) {
      dispatch({type: SET_CURRENT_REQUEST, payload: request})
      const positionId = data.position
      const simpleCert = data.simple_cert
      await dispatch(getCriterions(positionId, simpleCert))
    }
  }

  dispatch({type: SET_TEACHER_LOADING, payload: false})

};

export const updateRequestFiles = (requestId, files) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    
    const {request_files_json, awards} = getState().common.currentRequest

    const awardsList = awards || []
    const initialFiles = [...request_files_json, ...awardsList]

    const fileIds = files.filter(({file_id}) => file_id).map(({file_id}) => file_id)
    
    const filesForDelete = initialFiles.filter(({file_id}) => !fileIds.includes(file_id))
    const filesForUpload = files.filter(file => !file.file_id)

    await Promise.all(filesForDelete.map(async (file) => await dispatch(deleteRequestFile(requestId, file.file_id))))
    await Promise.all(filesForUpload.map(async (file) => await dispatch(uploadRequestFile(requestId, file))))
  }
} 

export const uploadRequestFile = (requestId, file) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const fd = new FormData();
    
    for ( const key in file ) {
      fd.append(key, file[key]);
    }

    const response = await fetch(
      `${BACKEND_URL_PREFIX}/certification/request/${requestId}/files`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        },
        body: fd
      }
    );
    if (response.ok) {

    }
  }  
};

export const deleteRequestFile = (requestId, fileId) => async (dispatch, getState) => {

  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    await fetch(
      `${BACKEND_URL_PREFIX}/certification/request/${requestId}/files/${fileId}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState)
      }
    )
  }
}

export const sendCriterion = (criterion, reqId) => async (dispatch, getState) => {

  dispatch({type: SET_TEACHER_FORM_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  
  if (getState().auth.token) {
    let returnState
    try {
      
      const { files, ...payload} = criterion
      const requestId = reqId || getState().common.currentRequest.id
      
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/certification/request/${requestId}/parts`,
        {
          method: "POST",
          ...buildDefaultHeaders(getState),
          body: JSON.stringify(payload),
        }
      );
      if (response.ok) {
        const {id: partId} = await response.json()
        
        const filesForUpload = files?.filter(({file}) => file)
        const filesForUploadChoice = files?.filter(({file}) => !file)

        await Promise.all(filesForUpload.map(async (file) => await dispatch(uploadCriterionFile(requestId, partId, file))))        
        await Promise.all(filesForUploadChoice.map(async (file) => await dispatch(uploadCriterionChoiceFile(partId, file))))        
        
        
        dispatch(getRequestParts(requestId))
        returnState = true
        // createNotification('success', 'Критерий успешно отправлен')
      } else {
        returnState = false
        createNotification('error', 'Ошибка отправки критерия')
      }
    } catch (ERR) {
      returnState = false
      createNotification('error', 'Ошибка отправки критерия')
    } finally {
      dispatch({type: SET_TEACHER_FORM_LOADING, payload: false})
      return returnState
    }
  }
}

export const updateCriterion = (criterion, requestId, partId) => async (dispatch, getState) => {

  dispatch({type: SET_TEACHER_FORM_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  
  if (getState().auth.token) {
    const { files, ...payload} = criterion
    let returnState
    try {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/certification/request/${requestId}/parts/${partId}`,
        {
          method: "PUT",
          ...buildDefaultHeaders(getState),
          body: JSON.stringify(payload)
        }
      );
      if (response.ok) {

        if (files) {
          const initialFiles = getState().common.requestParts.find(({id}) => id === partId).files_json
          const fileIds = files.filter(({id}) => id).map(({id}) => id)
          const filesForDelete = initialFiles.filter(({id}) => !fileIds.includes(id)).map(({id}) => id)
          const filesForUpload = files.filter(({id, file}) => !id && file)
          const filesForUploadChoice = files.filter(({id, file}) => !id && !file)
          
          await Promise.all(filesForDelete.map(async (fileId) => await dispatch(deleteCriterionFile(requestId, partId, fileId))))
          await Promise.all(filesForUpload.map(async (file) => await dispatch(uploadCriterionFile(requestId, partId, file))))        
          await Promise.all(filesForUploadChoice.map(async (file) => await dispatch(uploadCriterionChoiceFile(partId, file))))        
        }
       
        dispatch(getRequestParts(requestId))
        
        
        returnState = true
        // createNotification('success', 'Критерий успешно обновлен')
      } else {
        returnState = false
        createNotification('error', 'Ошибка обновления критерия')
      }
    } catch (ERR) {
      returnState = false
      createNotification('error', 'Ошибка обновления критерия')
    } finally {
      dispatch({type: SET_TEACHER_FORM_LOADING, payload: false})
      return returnState
    }
  }
}

export const updateRequestStatus = (requestId, status) => async (dispatch, getState) => {
  dispatch({type: SET_TEACHER_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
      let returnState
      const data = { status }
      try {
          const response = await fetch(`${BACKEND_URL_PREFIX}/certification/request/${requestId}`, {
              method: "PUT",
              body: JSON.stringify(data),
              ...buildDefaultHeaders(getState)
          });
          if (response.ok) {
              createNotification('success', 'Статус успешно изменен')
              returnState = true
          }
      } catch (ERR) {
          createNotification('error', 'Ошибка изменения статуса оценки')
          returnState = false
      } finally {
          dispatch({type: SET_TEACHER_LOADING, payload: false})
          return returnState
      }
  }
};

export const finishRequest = ({files, responsible, draft, requestFiles}) => async (dispatch, getState) => {
  dispatch({type: SET_TEACHER_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const {id: requestId, simple_cert} = getState().common.currentRequest

    files?.length && await Promise.all(files.map(async (file) => await dispatch(uploadFile(requestId, file))))

    requestFiles && await updateRequestFiles(requestId, requestFiles)

    if (responsible?.length) {
      
      const initialResponsibleIds = getState().common.currentRequest.responsible_json?.map(({id}) => id) || []
      const actualIds = responsible.filter(({id}) => id).map(({id}) => id)

      const addedResponsibles = responsible.filter(({id}) => !id).map(resp => ({...resp, request_id: requestId}))
      const removedResponsibleIds = initialResponsibleIds.filter(id => !actualIds.includes(id))

      removedResponsibleIds.length && await Promise.all(removedResponsibleIds.map(async (id) => await dispatch(removeResponsible(id, requestId))))
      addedResponsibles.length && await dispatch(addResponsibles(addedResponsibles, requestId))

    }

    const finalAction = draft ? {type: SET_TEACHER_LOADING, payload: false} : updateRequestStatus(requestId, simple_cert ? 3 : 2)

    await dispatch(finalAction)
    // dispatch(getRequests())

  }
}

export const addResponsibles = (data, requestId) => async (dispatch, getState) => {
  const response = await fetch(
    `${BACKEND_URL_PREFIX}/certification/request/${requestId}/responsibles`,
    {
      method: "POST",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify(data),
    }
  );
}

export const removeResponsible = (id, requestId) => async (dispatch, getState) => {
  const response = await fetch(
    `${BACKEND_URL_PREFIX}/certification/request/${requestId}/responsible/${id}`,
    {
      method: "DELETE",
      ...buildDefaultHeaders(getState)
    }
  );
}

export const uploadFile = (requestId, file) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const fd = new FormData();
    fd.append('file', file)

    const response = await fetch(
      `${BACKEND_URL_PREFIX}/certification/request/${requestId}/additional_file`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        },
        body: fd
      }
    );
    if (response.ok) {

    }
  }  
};

export const deleteFile = (requestId) => async (dispatch, getState) => {

  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(
      `${BACKEND_URL_PREFIX}/certification/request/${requestId}/additional_file`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState)
      }
    );
    if (response.ok) {

    }
 }  
};

export const uploadCriterionFile = (requestId, partId, fileObject) => async (dispatch, getState) => {

  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const fd = new FormData();
    Object.keys(fileObject).forEach(key => fd.append(key, fileObject[key]))

    const response = await fetch(
      `${BACKEND_URL_PREFIX}/certification/request/${requestId}/parts/${partId}/file`,
      {
        method: "POST",
        body: fd,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        }
      }
    );
    if (response.ok) {

    }
 }  
};

export const uploadCriterionChoiceFile = (part_id, file) => async (dispatch, getState) => {

  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const data = {...file, part_id}

    const response = await fetch(
      `${BACKEND_URL_PREFIX}/lms_file/files`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: getState().auth.first_token
        }
      }
    );
    if (response.ok) {

    }
 }  
};

export const deleteCriterionFile = (requestId, partId, fileId) => async (dispatch, getState) => {

  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(
      `${BACKEND_URL_PREFIX}/certification/request/${requestId}/parts/${partId}/file/${fileId}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState)
      }
    );
    if (response.ok) {

    }
  }  
};

export const deleteRequest = (requestId) => async (dispatch, getState) => {

  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(
      `${BACKEND_URL_PREFIX}/certification/request/${requestId}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState)
      }
    );
    if (response.ok) {
      dispatch(getRequests())
    }
  }  
};